import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/breaking-bad';
import Terms from '../termsOfService/breaking-bad.mdx';

const BreakingBadPage = () => (
  <ProductPageLayout
    title="Breaking Bad Intro Creator"
    emailAlias="breakingbad"
    website="https://breakingbadintrocreator.kassellabs.io"
    faq={faq}
    mergeTerms={false}
    mergeFaq={false}
    terms={<Terms />}
  />
);

export default BreakingBadPage;

